import axios from "axios"

export const query = (queryObj, url, callback) => {
  axios
    .get(`${url}graphql?query=${queryObj}`)
    .then((data) => {
      callback(data)
    })
    .catch(function (error) {
      console.error(error)
    })
}

export const queryGraphql = (queryObj, token, endpoint, callback) => {
  axios
    .post(`${process.env.GATSBY_ADMIN_URL}graphql`, queryObj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((data) => {
      callback(data)
    })
}
